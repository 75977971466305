
.services-page {
  .quicklinks {
    text-align: right;

    .row:first-of-type {
      margin-top: 25px;
    }
  }

  .services-description {
    padding: 60px 0;

    h3:first-child {
      margin-top: 0;
    }
  }

  section .service-title {
    font-size: 42px;
  }

  .our-goals {
    padding: 40px 0 50px;
  }

  .icon {
    &--quicklink {
      margin: 10px;
      margin-right: 0;
      max-height: 50px;
    }

    &--section {
      max-height: 100px;
    }
  }

  .list {
    padding-left: 16px;

    &--spaced {
      margin: 30px 0;

      > li {
        margin-bottom: 15px;
      }
    }
  }

  .telecommunications {
    background: url('./images/section-telecommunications-bg_tmp.jpg') no-repeat scroll 0 0;
    background-size: cover;
    min-height: 800px;
    padding: 60px 0;
  }

  .electrical {
    background: url('./images/section-electrical-bg_tmp.jpg') no-repeat scroll 0 0;
    background-size: cover;
    min-height: 800px;
    padding: 60px 0;
  }

  .street-lighting {
    background: url('./images/section-street-lighting-bg_tmp.jpg') no-repeat scroll 0 0;
    background-size: cover;
    min-height: 800px;
    padding: 60px 0;
  }

  .other-services {
    background: url('./images/section-other-services-bg_tmp.jpg') no-repeat scroll 0 0;
    background-size: cover;
    min-height: 600px;
    padding: 60px 0;

    .fa {
      margin: 0 5px;
    }
  }

  // Everywhere except mobile phones
  @media screen and (min-width: 767px) {
    .margin {
      &--top {
        &-30 {
          padding-top: 30px;
        }
      }
    }
  }

  // Only at mobile phones
  @media screen and (max-width: 767px) {
    .quicklinks {
      text-align: center;
    }
  }
}
