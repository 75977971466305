@import '../../styles/variables';

.vertical-carousel {
  background: $img-pattern--background-noise;
  overflow: hidden; // NOTE: required by absolute col background image

  .carousel-content {
    padding: 30px 0;

    .carousel-cell {
      width: 100%;
    }
  }

  .carousel-navigation {
    > ul {
      color: $color-white;
      list-style: none;
      margin: 0;
      position: inherit;
      z-index: 1;
    }

    .navigation-item {
      font-family: $font-serif-normal;
      height: 45px;

      &:first-child {
        margin-bottom: 10px;
      }

      .nav-btn {
        background: transparent;
        border: transparent;
      }

      // Icon and title opacity animation
      .title {
        font-size: 17px;
        opacity: 0.3;
        transition: opacity $transition-fast ease-in-out;
        vertical-align: top;
      }

      &--selected {
        .icon--bullet::before {
          opacity: 1;
        }

        .title {
          opacity: 1;
        }
      }

      &:hover {
        .icon--bullet::before {
          opacity: 0.5;
        }

        .title {
          opacity: 0.7;
        }
      }

      // TODO: Line between bullets
      // &::after {
      //   border-left: 1px solid $color-white;
      //   content: '';
      //   height: 30px;
      //   left: 55px; // adjust manually
      //   position: absolute;
      //   width: 1px;
      // }
    }

    // Navigation bullets
    .icon--bullet {
      background: $color-white;
      border: 1px solid $color-white;
      border-radius: 50%;
      display: inline-block;
      height: 9px;
      margin: 5px 25px 0 5px;
      width: 9px;

      // Circle around bullet
      &::before {
        border: 1px solid #fff;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 30px;
        margin-left: -11px;
        margin-top: -11px;
        opacity: 0;
        transition: opacity $transition-slow ease-in-out;
        width: 30px;
      }
    }
  }

  .navigation-background {
    background-repeat: no-repeat;
    background-size: cover;
    // height: 100%;
    position: absolute;
    top: 0;
    width: 100vh;
    z-index: 0;
  }
}

@media screen and (max-width: 767px) {
  .carousel-navigation {
    overflow: hidden; // Fix for background on reordered columns
    padding: 0;
  }
}

