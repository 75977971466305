@import '../../styles/variables.scss';
.not-found-page {
  > .container {
    padding-top: 80px;
    text-align: center;

    p {
      color: $color-cascade;
      font-family: $font-serif-normal;
      font-weight: 300;

      > a {
        color: $color-cascade;
        text-decoration: underline;
      }
    }
  }
}
