@import '../../styles/variables';

.hidden-email {
  > .hidden-email {
    &__btn {
      background: transparent;
      border-radius: 0;
      border-width: 0;
      color: $color-beige;
      display: inline-block;
      line-height: 1;
      padding: 0;
      position: relative;
      text-align: left;
      text-decoration: none;
      white-space: normal;
    }

    &__link {
      text-decoration: underline;
    }
  }
}
