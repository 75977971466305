@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.header-robrus {
  background-color: $color-white--transparent-hight;
  border-bottom: 1px solid $color-cascade;
  transition: background-color $transition-slow ease-in-out;
  z-index: 10;

  &.header-minimized {
    background-color: $color-white--transparent-low;
    box-shadow: 0 3px 30px $color-cascade;

    .extra-content {
      margin: 0;
      opacity: 0;
    }

    .navbar-brand > img {
      height: 40px;
      top: 6px;
      width: 100px;
    }
  }
}

// Search and "Portal login"
.extra-content {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
  transition: $transition-slow;

  .divider-vertical {
    @include divider-vertical(20px, 3px 15px);
  }

  .search {
    display: inline;
    margin-top: auto;

    input {
      border-bottom: 1px solid $color-white;
      color: $color-white;
      min-width: 225px;
      padding: 0 3px 3px;

      &::placeholder {
        text-transform: uppercase;
      }
    }

    button {
      padding-right: 0;
    }
  }
}

// Navbar: react-bootstrap custom componentClass
navbar-robrus {
  .navbar-brand {
    padding: 0;

    > img {
      height: 83px;
      padding: 0;
      position: absolute;
      top: 25px;
      transition: $transition-slow;
      width: 180px;
    }
  }

  .navbar-collapse {
    padding: 0;
  }

  .navbar-toggle {
    border-color: $color-green;

    > .icon-bar {
      background-color: $color-green;
    }
  }

  .navbar-nav {
    // nav list item => NavItem
    li {
      // Navigation item underline
      &::before {
        background: $color-green;
        bottom: -1px;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 100%;
        transition-duration: $transition-fast;
        transition-property: right;
        transition-timing-function: ease-out;
        z-index: 30;
      }

      // Show navigation item underline on hover
      &:hover::before {
        right: 0;
      }
    }

    // Show navigation item underline for current path, with class active
    .active::before {
      right: 0;
    }

    a {
      background-color: transparent;
      color: $color-black;
      font-family: $font-medium;
      padding: 15px 20px; // NOTE: custom padding worse for responsiveness
      text-transform: uppercase;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

// Styles for responsiveness support
@media screen and (max-width: 767px) {
  .extra-content {
    justify-content: center;
    margin-bottom: 55px;

    .search > input {
      min-width: 110px;
      width: 110px;
    }

    .btn-robrus {
      padding: 0 15px;
    }
  }

  navbar-robrus {
    .navbar-brand > img {
      padding-left: 15px;
      top: 75px;
    }

    // Update style for open menu
    .navbar-collapse {
      background-color: $color-white;

      .navbar-nav {
        margin: 7.5px 15px;
      }
    }
  }
}
