// @import '~styles/variables';
@import '~@material/checkbox/dist/mdc.checkbox.min.css';

.mdc-checkbox {
  margin-left: -11px;

  &--theme-robrus {
    .mdc-checkbox {
      &::after,
      &::before {
        background-color: transparent;
      }

      // Disable important rule, because we are overwriting mdc default styles
      &__background::before {
        opacity: 0 !important; // stylelint-disable-line declaration-no-important
        transition: opacity $transition-slow ease-in-out;
      }

      &:hover > .mdc-checkbox {
        &__background::before {
          opacity: 0.26 !important; // stylelint-disable-line declaration-no-important
          transition: opacity $transition-fast ease-in-out;
        }
      }
    }

    .checkbox-label {
      font-weight: normal;
      user-select: none;

      &.label--align-middle {
        margin: 0;
        transform: translateY(-50%);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
