@import '../../styles/variables.scss';

.home-page {
  .services {
    // TODO: refactor paddings that are reapiting in each section
    padding-bottom: 60px;
    padding-top: 40px;

    .services-description {
      margin: 40px 0;

      .description-title {
        font-family: $font-normal;
        font-weight: bold;
        text-transform: uppercase;
      }

      .service-icon {
        margin-bottom: 20px;
      }
    }
  }

  .nbn-telstra {
    > h3 {
      margin-top: 0;
    }

    > .description {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }

  .our-team {
    padding-bottom: 60px;
    padding-top: 40px;

    .position {
      letter-spacing: 0.1em;
    }

    .team-member {
      margin: 40px 0;
      margin: auto;
    }

    @media screen and (max-width: 767px) {
      .photo {
        margin: 0 auto;
        margin-bottom: 20px;
      }

      .position {
        text-align: center;
      }
    }
  }

  .our-clients {
    background: $img-pattern--background-noise;
    padding: 40px 0;

    .client-logo {
      filter: grayscale(1);
      height: 45px;
      margin: 25px auto;
      transition: $transition-fast;
      width: 145px;

      &:hover {
        filter: none;
      }
    }
  }

  .callback-request {
    padding-bottom: 60px;
    padding-top: 40px;

    .btn {
      margin-top: 30px;
    }
  }
}
