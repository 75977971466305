@import '../../variables.scss';

@import './checkbox';
@import './textfield';

// MDC-Web CSS variables
// https://github.com/material-components/material-components-web/blob/master/docs/theming.md
:root {
  --mdc-theme-primary: $color-beige;
}
