@import '../variables.scss';

html,
body {
  height: 100%;
}

body {
  color: $color-black;
  font-family: $font-normal;
}

// Selected text and elements
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
li,
a,
input,
span,
label,
em,
img {
  &::selection {
    background-color: $color-beige;
    color: $color-white;
  }
}

h1,
h2,
h3 {
  color: $color-cascade;
  cursor: text;
  font-family: $font-serif-normal;
  font-weight: 300;
  line-height: 1.5em;
}

h4,
h5,
h6 {
  cursor: text;
  font-family: $font-medium;
  letter-spacing: 0.1em;
  word-spacing: 0.1em;
}

strong {
  cursor: text;
  font-family: $font-medium;
}

a {
  color: $color-beige;
  cursor: pointer;

  &:hover {
    color: $color-beige;
  }

  &:active,
  &:focus {
    color: $color-green;
  }
}

p {
  cursor: text;
  font-size: 15px;
}

// Input placeholder text opacity
input {
  cursor: grab;

  &::placeholder {
    opacity: 0.8;
  }
}

// Outline for focused input and button
button,
input {
  &:focus {
    outline: 0;
  }
}
