@import '../../../styles/variables.scss';

.footer {
  background: $color-green;
  color: $color-white;
  // TODO: classify and move font-size to constants
  font-size: 0.9em;
  padding: 40px 0;

  .copyright {
    text-align: right;

    > .rights {
      margin-top: 95px;
    }

    > .scroll-top {
      bottom: 55px;
      position: absolute;
      right: 10px;
    }
  }

  // Footer list style
  ul {
    padding: 0;

    > li {
      cursor: text;
      list-style: none;
    }
  }

  li {
    > strong {
      cursor: default;
    }

    > a {
      color: $color-white;
    }
  }

  // Styles for responsiveness support
  @media screen and (max-width: 767px) {
    padding: 30px 0;

    .contact-info {
      margin: 0 auto;
      width: 290px;
    }

    .copyright {
      text-align: center;

      > .rights {
        margin: 20px auto;
        text-align: right;
        width: 290px;
      }

      > .scroll-top {
        position: static;
      }
    }
  }
}
