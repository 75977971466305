@import '../variables.scss';

// Overwrite default bootstrap styles
.btn:active.btn:focus {
  outline: 0;
}

.btn {
  &:hover,
  &:focus {
    outline: 0;
  }
}

// Custom style
.btn-robrus {
  border: 2px solid $color-white;
  border-radius: 0;
  color: $color-white;
  font-family: $font-bold;
  height: 38px;
  line-height: 15px;
  padding: 0 25px;
  text-transform: uppercase;
  transition: $transition-fast;

  &:hover,
  &:focus {
    color: $color-white;
  }

  &:hover {
    background-color: $color-white;
    color: $color-green;
  }

  // Button colors
  &.btn--color {
    &-green {
      border-color: $color-green;
      color: $color-green;

      &:hover {
        background-color: $color-green;
        color: $color-white;
      }
    }

    &-beige {
      border-color: $color-beige;
      color: $color-beige;

      &:hover {
        background-color: $color-beige;
        color: $color-white;
      }
    }

    &-cascade {
      border-color: $color-cascade;
      color: $color-cascade;

      &:hover {
        background-color: $color-cascade;
        color: $color-white;
      }
    }
  }

  // Button colors for filled buttons
  &.btn-filled {
    background-color: $color-white;
    color: $color-green;

    &.btn--color-green {
      background-color: $color-green;
      color: $color-white;

      &:hover {
        background-color: transparent;
        color: $color-green;
      }
    }

    &.btn--color-beige {
      background-color: $color-beige;
      color: $color-white;

      &:hover {
        background-color: transparent;
        color: $color-beige;
      }
    }

    &.btn--color-cascade {
      background-color: $color-cascade;
      color: $color-white;

      &:hover {
        background-color: transparent;
        color: $color-cascade;
      }
    }
  }

  &.btn-fullwidth {
    width: 100%;
  }

  // Button sizes
  &.btn {
    &-sm {
      height: 26px;
    }
  }
}
