@import "../../styles/variables.scss";
.faq-page {
  margin: 90px 0;

  .container > section {
    margin-bottom: 73px;

    .title {
      border-top: 5px solid $color-cascade;
      line-height: 1.3;
      margin: 0 95px 0 0;
      padding: 18px 15px 9px 0;
      text-transform: uppercase;
    }
  }
  // Styles for responsiveness support
  @media screen and (max-width: 991px) {
    .container > section {
      .title {
        margin-right: 0;
      }
    }
  }
}
