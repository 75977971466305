@import '../variables.scss';

@font-face {
  font-family: 'MuseoSlab-500';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/MuseoSlab-500/MuseoSlab-500.eot');
  src: url('../fonts/MuseoSlab-500/MuseoSlab-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSlab-500/MuseoSlab-500.woff') format('woff'),
    url('../fonts/MuseoSlab-500/MuseoSlab-500.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueThin';
  font-style: normal;
  font-weight: lighter;
  src: url('../fonts/HelveticaNeueThin/HelveticaNeueThin.eot');
  src: url('../fonts/HelveticaNeueThin/HelveticaNeueThin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueThin/HelveticaNeueThin.woff') format('woff'),
    url('../fonts/HelveticaNeueThin/HelveticaNeueThin.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/HelveticaNeueLight/HelveticaNeueLight.eot');
  src: url('../fonts/HelveticaNeueLight/HelveticaNeueLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueLight/HelveticaNeueLight.woff') format('woff'),
    url('../fonts/HelveticaNeueLight/HelveticaNeueLight.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/HelveticaNeueMedium/HelveticaNeueMedium.eot');
  src: url('../fonts/HelveticaNeueMedium/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueMedium/HelveticaNeueMedium.woff') format('woff'),
    url('../fonts/HelveticaNeueMedium/HelveticaNeueMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueBold';
  font-style: normal;
  font-weight: bolder;
  src: url('../fonts/HelveticaNeueBold/HelveticaNeueBold.eot');
  src: url('../fonts/HelveticaNeueBold/HelveticaNeueBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueBold/HelveticaNeueBold.woff') format('woff'),
    url('../fonts/HelveticaNeueBold/HelveticaNeueBold.ttf') format('truetype');
}

.text {
  // Font styles
  &--style {
    &-light {
      font-family: $font-thin;
    }

    &-normal {
      font-family: $font-normal;
    }

    &-medium {
      font-family: $font-medium;
    }

    &-bold {
      font-family: $font-bold;
    }
  }

  &--weight {
    &-min {
      font-weight: 100;
    }

    &-max {
      font-weight: 900;
    }
  }

  // Fonts colors
  &--color {
    &-default {
      color: $color-black;
    }

    &-inverse {
      color: $color-white;
    }

    &-green {
      color: $color-green;
    }

    &-red {
      color: $color-red;
    }

    &-beige {
      color: $color-beige;
    }

    &-feldgrau {
      color: $color-feldgrau;
    }

    &-cascade {
      color: $color-cascade;
    }
  }
}
