// Third-party styles libraries
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~animate.css/animate.min.css';
@import '~aos/dist/aos.css';
@import '~flickity/dist/flickity.min.css';
@import '~font-awesome/css/font-awesome.min.css';

@import './elements/global';
@import './elements/common';
@import './elements/fonts';
@import './elements/buttons';
@import './elements/mdc-web/main';

// pages
@import '../pages/AppWrapper/styles';
@import '../pages/HomePage/styles';
// @import '~pages/AboutUs/styles';
// @import '~pages/News/styles';
// @import '~pages/WhyChooseUs/styles';
// @import '~pages/Services/styles';
// @import '~pages/ContactUs/styles';
// @import '~pages/Login/styles';
// @import '~pages/FaqPage/styles';
// @import '~pages/NotFoundPage/styles';

// NOTE: increase page height when there is no content (keep footer at page bottom)
.robrus-webapp,
.robrus-webapp > div:first-child, // Set App container width 100%
.robrus-webapp > div:first-child > .app-wrapper {
  height: 100%; // required for min-height: x% of wrapper-content
}

button {
  background: transparent;
}
