@import '../../../styles/variables.scss';

$color-beige--carousel-link: rgba(211, 157, 130, 1);

.carousel-robrus {
  color: $color-white;
  z-index: 0;

  // We don't use static-banner anymore, but it save for future
  .static-banner {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 150px;
    z-index: 5;

    > .banner {
      padding: 0;

      &-right {
        text-align: right;
      }
    }
  }

  .banner-title {
    font-size: 43px;
    letter-spacing: -1px;
    line-height: 1.15em;
    padding-top: 45px;
  }

  .info-block {
    border: 2px solid $color-gray;
    padding: 20px;
    text-align: right;

    h3 {
      height: 30px; // NOTE: smaller height decreasing indent between h3 and divider
      margin-top: 0;
    }

    .divider {
      margin-left: auto;
      width: 65px;
    }
  }

  .info-home {
    height: 370px;
  }

  .latest-news {
    line-height: 1.3em;
    padding-bottom: 5px;

    a {
      color: $color-white;
    }

    &.top-news {
      font-family: $font-medium;
      letter-spacing: -0.05ch;
    }
  }

  .carousel-news-link {
    color: $color-beige--carousel-link;
    font-size: 15px;
  }

  .carousel-cell {
    margin-right: 10px;
    width: 100%;

    .cell-content {
      margin: 180px auto 45px;
      padding: 0;
    }

    .img-centered {
      height: 100vh;
      position: fixed;
      top: 0;
      z-index: -1;
    }
  }

  // position dots in carousel
  .flickity-page-dots {
    bottom: 20px;

    // color for circles
    .dot {
      background: transparent;
      border: 2px solid $color-white;
      height: 12px;
      opacity: 1;
      width: 12px;

      // fill-in selected dot
      &.is-selected {
        background: $color-white;
      }

      // hide cells navigation if there is only one cell
      &:only-child {
        display: none;
      }
    }
  }

  // Custom transition effects
  &.carousel-transition-fade {
    // Disable rule, because we need to everwrite flickity html style tag
    /* stylelint-disable declaration-no-important */
    .flickity-slider {
      transform: translateX(0%) !important;

      .carousel-cell {
        left: 0 !important;
        opacity: 0;
        transition: opacity $transition-slow ease-in-out;
        z-index: 10;

        &.is-selected {
          opacity: 1;
          z-index: 15;
        }

        // NOTE: fix for blinding first slide during page switching
        // &:first-child {
        //   opacity: 1;
        //   transition: opacity $transition-slow ease-in-out;
        // }
      }
    }
    /* stylelint-enable declaration-no-important */
  }

  @media screen and (max-width: 767px) {
    .banner.banner-left {
      margin-bottom: 55px;
    }

    .carousel-cell {
      > .cell-content {
        padding: 0 15px;
      }

      .img-centered {
        height: 100%;
      }
    }
  }

  // Fix for responsive carousel image on large screens
  @media screen and (min-width: 1700px) {
    .carousel-cell > .img-centered {
      height: auto;
      width: 100%;
    }
  }
}
