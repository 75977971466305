$color-green: rgba(61, 106, 84, 1);
$color-red: rgba(255, 0, 0, 1);
$color-beige: rgba(184, 135, 109, 1);
$color-cascade: rgba(144, 156, 150, 1);
$color-feldgrau: rgba(87, 89, 88, 1);


$color-white: rgba(244, 244, 244, 1);
$color-white--transparent-low: rgba(244, 244, 244, 0.95);
$color-white--transparent-hight: rgba(244, 244, 244, 0.2);

$color-black: rgba(73, 74, 75, 1);
$color-gray: rgba(163, 175, 169, 1);
$color-gray-light: #dedede;

$transition-fast: 0.3s;
$transition-slow: 0.7s;

$font-thin: 'HelveticaNeueThin';
$font-normal: 'HelveticaNeue';
$font-medium: 'HelveticaNeueMedium';
$font-bold: 'HelveticaNeueBold';
$font-serif-normal: 'MuseoSlab-500';

$img-pattern--background-noise: url('./images/pattern-background-noise.png');
