@import '../../styles/variables.scss';

.card-robrus {
  background: url('./images/card-default-background_2_tmp.jpg') no-repeat scroll 0 0;
  background-size: cover;
  padding: 80px 0;

  .card-content {
    background-color: $color-white--transparent-low;
    padding: 50px;
  }

  @media screen and (max-width: 767px) {
    .card-content {
      padding: 20px;
    }
  }
}
