@import '../variables.scss';

.divider {
  &--size {
    &-sm {
      border-width: 1px;
    }

    &-md {
      border-width: 5px;
    }

    &-lg {
      border-width: 8px;
    }
  }

  &--spacing {
    &-sm {
      margin: 15px 0;
    }

    &-md {
      margin: 30px 0;
    }

    &-lg {
      margin: 40px 0;
    }
  }

  &--color {
    &-default {
      border-color: $color-black;
    }

    &-inverse {
      border-color: $color-white;
    }

    &-green {
      border-color: $color-green;
    }

    &-red {
      border-color: #f00;
    }

    &-beige {
      border-color: $color-beige;
    }

    &-gray {
      border-color: $color-gray;
    }
  }

  // TODO: try to implement &-vertical selector
}

.no {
  &--padding {
    padding: 0;
  }
}

.anchor {
  display: block;
  height: 76px; // header height
  margin-top: -76px; // header height
  visibility: hidden;
}

.row {
  &--col-spaced {
    &--md {
      > [class*='col-'] {
        padding: 0 25px;
      }
    }
  }
}

// NOTE: align-vertically and col-group not available for mobile phones
@media screen and (min-width: 767px) {
  .row {
    &--align-vertically {
      display: table;
      height: 100%;
      margin: 0 auto;
      table-layout: fixed;
      width: 100%;
    }

    > .row--col-group {
      > [class*='col-'] {
        margin-top: 15px;
      }
    }

    &--spacing-off {
      [class*='col-'] {
        padding: 0;
      }
    }
  }

  .col {
    &--align-vertically {
      display: table-cell;
      float: none;
      height: 100%;
    }

    &--vertically {
      &-top {
        vertical-align: top;
      }

      &-middle {
        vertical-align: middle;
      }

      &-bottom {
        vertical-align: bottom;
      }
    }
  }
}

// NOTE: row--grid available only for mobile phones
@media screen and (max-width: 767px) {
  .row {
    .row--grid {
      [class*='col-'] + [class*='col-'],
      [class*='row--col-'] > [class*='col-'] {
        margin-top: 15px;
      }
    }

    &.row--reorder--xs {
      direction: rtl; /* Fix the horizontal alignment */
      transform: rotate(180deg);

      > [class*='col-'] {
        direction: ltr; /* Fix the horizontal alignment */
        transform: rotate(-180deg);
      }
    }
  }
}
