@import '../../styles/variables';

.article {
  .highlighted {
    border-left: 5px solid $color-cascade;
    padding-left: 25px;
  }

  .content {
    &-title {
      &--fixed-height {
        min-height: 60px; // NOTE: min-height required for correct col responsiveness
      }
    }

    &-navigation {
      margin: 40px 0 20px;
    }
  }
}
