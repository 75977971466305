.contact-us-page {
  .contact-info {
    padding: 60px 0;

    .general-enquiries {
      font-size: 1.2em;
    }

    .find-us-location {
      img {
        margin-left: auto;
        max-height: 233px;
        // max-width: 330px;
      }
    }

    p {
      margin: 0;
    }

    // TODO: move to the global styles if it will repeats one more time
    ul {
      padding: 0;

      > li {
        cursor: text;
        list-style: none;
      }
    }

    // Styles for responsiveness support
    @media screen and (max-width: 767px) {
      .find-us-location {
        h3 {
          margin-top: 0;
        }

        img {
          margin: 0 auto;
        }
      }
    }
  }

  .callback-form {
    .mdc-textfield--multiline {
      margin: 32px 0 8px;

      > .mdc-textfield {
        &__input {
          height: 100px;
        }
      }
    }

    .submit-form {
      margin-top: 20px;
    }
  }
}
