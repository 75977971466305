@import '../../styles/variables';

.collapse-element {
  border-bottom: 1px solid $color-gray-light;
  padding: 10px 0;

  &:first-child {
    border-top: 1px solid $color-gray-light;
  }

  .btn.btn-link,
  button {
    background: transparent;
    border-radius: 0;
    border-width: 0;
    color: $color-black;
    display: block;
    font-size: 16px;
    line-height: 1;
    padding: 13px 30px 14px 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    white-space: normal;
    width: 100%;

    &:focus,
    &:hover {
      background: transparent;
    }

    > .faq-anchor {
      display: none;
      margin-left: 10px;
    }

    > .down {
      display: block;
      height: 16px;
      position: absolute;
      right: 0;
      top: 13px;
      width: 16px;

      &::before {
        background-color: $color-cascade;
        content: '';
        height: 100%;
        left: 50%;
        margin-left: -2px;
        position: absolute;
        top: 0;
        transition: transform 0.25s ease-out;
        width: 4px;
      }

      &::after {
        background-color: $color-cascade;
        content: '';
        height: 4px;
        left: 0;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        transition: transform 0.25s ease-out;
        width: 100%;
      }
    }
  }

  p {
    margin-bottom: 12px;
  }

  &.true {
    button,
    .btn.btn-link {
      font-weight: 600;

      &:hover {
        .faq-anchor {
          display: inline;
        }
      }

      .down {
        &::before {
          transform: rotate(90deg);
        }

        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}
