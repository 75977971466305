.news-page {
  [class*='news--'] {
    &:first-child {
      margin-bottom: 60px;
      margin-top: 60px;
    }

    &:last-child {
      margin-bottom: 60px;
    }

    + [class*='news--'] {
      margin-top: 30px;
    }
    
  }
}
