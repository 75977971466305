.about-us-page {
  .about-info {
    padding: 40px 0;

    p {
      margin: 20px 0;
    }
  }

  .certifications-and-accreditations {
    > h3 {
      margin-top: 0;
    }

    > .description {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }

  .our-team {
    padding-bottom: 60px;
    padding-top: 40px;

    .row {
      padding-top: 40px;

      // For all rows, except first row with heading.
      &:first-child {
        padding-top: 0;
      }
    }

    .position {
      letter-spacing: 0.1em;
    }

    .photo {
      margin: 0 auto;
      margin-bottom: 30px;

      &-sm {
        max-height: 200px;
      }
    }
  }
}
