@import './header/styles';
@import './carousel/styles';
@import './footer/styles';

.app-wrapper {
  // Make padding from top, because of header
  .wrapper-content {
    min-height: 80%;
    padding-top: 150px;
  }

  // Don't make padding from carousel
  .carousel + .wrapper-content {
    padding-top: 0;
  }
}
