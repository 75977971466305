@import '../../variables.scss';
// @import '~@material/textfield/dist/mdc.textfield.min.css';

.mdc-textfield {
  &--theme-robrus {
    font-size: 1em;

    // Override default style of elements that are placed inside mdc-textfield
    .mdc-textfield {
      &__label {
        font-family: $font-medium;
        margin: 0;
      }

      &__input {
        font-family: $font-normal;
      }
    }

    // Custom additional styling for theme-robrus
    &.theme-robrus--fullwidth {
      width: 100%;

      .mdc-textfield {
        &__input {
          width: 100%;
        }
      }
    }

    // For for selected label
    label::selection {
      background-color: $color-beige;
      color: $color-white;
    }
  }
}
